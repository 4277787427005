.nav-light-butts {
  div.btn-outline-primary {
    color: #fff;
    border-color: #fff;
  }
  div.btn-outline-primary:hover {
    border-color: #2373c7 !important;
  }
}

.nav-sticky .nav-light-butts {
  div.btn-outline-primary {
    color: unset;
    border-color: unset;
  }
}

.nav-sticky .nav-light-butts {
  div.btn-outline-primary:hover {
    color: #fff;
    border-color: unset;
  }
}

.bg-overlay-dark {
  background-color: #2f3741b3 !important;
}

#error-msg {
  color: #e90c0c;
  margin-bottom: 10px;
  font-size: 18px;
  text-align: center;
}

#simple-msg {
  margin-bottom: 10px;
  font-size: 18px;
  text-align: center;
}

.grecaptcha-badge {
  display: none !important;
}

.intl-tel-input.allow-dropdown {
  width: 100%;
}

#topnav .buy-button {
  display: none;
}

.title-heading-mob {
  margin-top: 0px;
}

.menu-hide-mob {
  display: block;
}

@media (min-width: 1201px) {
  #topnav .buy-button {
    display: block;
  }
}

@media (min-width: 1201px) {
  .menu-hide-mob {
    display: none !important;
  }
  .title-heading-mob {
    margin-top: 50px;
  }
}

#ardmore-logo-div {
  bottom: 8px;
}

#nh-logo-div {
  top: 30px;
}

#fec-logo-div {
  top: 30px;
}

@media (min-width: 768px) {
  #kd-logo-div {
    left: 5px;
    bottom: 30px;
  }
}

@media (min-width: 992px) {
  #ardmore-logo-div {
    top: 30px;
  }

  #greenland-logo-div {
    top: 39px;
  }

  #kd-logo-div {
    left: 0px;
    bottom: 0px;
  }
}

.case-study-div-click {
	cursor: pointer;
}

.btn-outline-white {
	color: #fff;
	border-color: #fff;
}

.pricing-tick {
	fill: #2373c7;
	height:17px;
	width:17px;
	position: relative;
	bottom:1px;
}
